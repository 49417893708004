import { serializeTokens } from './tokens'
import { SerializedVaultConfig } from './types'

const serializedTokens = serializeTokens()


export const oldYards: SerializedVaultConfig[] = [
    {
        pid: 1,
        lpSymbol: 'SPYN-BUSD',
        symbol: 'BUSD',
        lpAddresses: {
            97: '0xF9addC84D1D61c4261F797089e55c1E0920874d3',
            56: '0x0e587eaeFC234965ef5b2219983E7Df06b42dAE3',
        },
        contractAddresses: {
            97: '0xaA3fbB01193b88f78fb868dA152B15eEe8C6b572',
            56: '0x30754cF864903232bf5eBC12af929b168Ea1E6DC',
        },
        isETH: false,
        token: serializedTokens.busd,
    },
    {
        pid: 2,
        symbol: 'BNB',
        lpSymbol: 'SPYN-BNB',
        lpAddresses: {
            97: '0x2a4a6f0163900d30469e780cb8739fc4df8cbecf',
            56: '0xfcB07994C68d986D4d534709314A021e56bBBFf0',
        },
        contractAddresses: {
            97: '0x752b76C7f05a39a0506e92C793201d62f40A9A3B',
            56: '0x42F8479fCe1053cefe8d95441F61DD5C2942F1E4',
        },
        isETH: true,
        token: serializedTokens.wbnb,
    },
    {
        pid: 4,
        symbol: 'USDC',
        lpSymbol: 'SPYN-USDC',
        lpAddresses: {
            97: '0x296ae37b2366e76A228f439826e5B413465162b4',
            56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
        },
        contractAddresses: {
            97: '0x7cA263bb1B70146B2994D69EFB321668f297C963',
            56: '0x4E24bE6d7234d2e82F7C0F623AC69B434190006D',
        },
        isETH: false,
        token: serializedTokens.usdc,
    },
    {
        pid: 5,
        symbol: 'SPY',
        lpSymbol: 'SPYN-SPY',
        lpAddresses: {
            97: '0xf63939219f12D687cb8BC7019D3C2831d1C3dA68',
            56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
        },
        contractAddresses: {
            97: '0x3e4cC94B035D82122fC3065CbefEaeac3B99797F',
            56: '0x4E24bE6d7234d2e82F7C0F623AC69B434190006D',
        },
        isETH: false,
        token: serializedTokens.spy,
    }
]


const yards: SerializedVaultConfig[] = [
    {
        pid: 1,
        lpSymbol: 'SPYN-BUSD',
        symbol: 'BUSD',
        lpAddresses: {
            97: '0xF9addC84D1D61c4261F797089e55c1E0920874d3',
            56: '0x0e587eaeFC234965ef5b2219983E7Df06b42dAE3',
        },
        contractAddresses: {
            97: '0x38009935A350478676C6956Ba381Ef61667890D4',
            56: '0x30754cF864903232bf5eBC12af929b168Ea1E6DC',
        },
        isETH: false,
        token: serializedTokens.busd,
    },
    {
        pid: 2,
        symbol: 'BNB',
        lpSymbol: 'SPYN-BNB',
        lpAddresses: {
            97: '0x2a4a6f0163900d30469e780cb8739fc4df8cbecf',
            56: '0xfcB07994C68d986D4d534709314A021e56bBBFf0',
        },
        contractAddresses: {
            97: '0xCD5aD07E8CDa753ab5DcF2dD16687ef91842D5Cc',
            56: '0x42F8479fCe1053cefe8d95441F61DD5C2942F1E4',
        },
        isETH: true,
        token: serializedTokens.wbnb,
    },
    {
        pid: 4,
        symbol: 'USDC',
        lpSymbol: 'SPYN-USDC',
        lpAddresses: {
            97: '0x296ae37b2366e76A228f439826e5B413465162b4',
            56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
        },
        contractAddresses: {
            97: '0x7cA263bb1B70146B2994D69EFB321668f297C963',
            56: '0x4E24bE6d7234d2e82F7C0F623AC69B434190006D',
        },
        isETH: false,
        token: serializedTokens.usdc,
    },
    {
        pid: 5,
        symbol: 'SPY',
        lpSymbol: 'SPYN-SPY',
        lpAddresses: {
            97: '0xf63939219f12D687cb8BC7019D3C2831d1C3dA68',
            56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
        },
        contractAddresses: {
            97: '0x2E1fc6B5F6b9177c84CA753f439B6f18C7fe15ea',
            56: '0x4E24bE6d7234d2e82F7C0F623AC69B434190006D',
        },
        isETH: false,
        token: serializedTokens.spy,
    },
]

export default yards