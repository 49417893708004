import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const configWithSpyPools: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: '',
    href: '/governance/proposals',
    icon: 'More',
    hideSubNav: true,
    items: [
      {
        label: t('Governance'),
        href: '/governance/proposals',
      },
      {
        label: t('SPYN Dashboard'),
        href: '/spyn-dashboard',
      },
    ],
  },
  {
    label: t('TRADE'),
    href: '/swap',
    icon: 'Trade',
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('EARN'),
    href: '/farms',
    icon: 'Earn',
    items: [
        {
          label: t('Farms'),
          href: '/farms',
        },
      {
        label: t('SPYN NFT Pool'),
        href: '/spyn-nft-pools',
      },
      {
        label: t('SPY NFT Pool'),
        href: '/spy-nft-pools',
      }
    ],
  },
  {
    label: t('REFERRAL'),
    href: '/referral',
    icon: 'Ticket',
    items: [
      {
        label: t('Referral'),
        href: '/referral',
      },
    ],
  },
  {
    label: t('NFTs'),
    href: '/nfts',
    icon: 'Nft',
    items: [
      {
        label: t('NFTs SPYN'),
        href: '/nfts',
      },
      {
        label: t('NFTs SPY'),
        href: 'https://app.spy-token.io/nfts',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    //   {
    //     label: t('NFT Signatures'),
    //     href: '/signatures',
    //   }
    ]
  },
]

const configWithoutSpyPools: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: '',
    href: '/governance/proposals',
    icon: 'More',
    hideSubNav: true,
    items: [
      {
        label: t('Governance'),
        href: '/governance/proposals',
      },
      {
        label: t('SPYN Dashboard'),
        href: '/spyn-dashboard',
      },
    ],
  },
  {
    label: t('TRADE'),
    href: '/swap',
    icon: 'Trade',
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('EARN'),
    href: '/farms',
    icon: 'Earn',
    items: [
        {
          label: t('Farms'),
          href: '/farms',
        },
      {
        label: t('SPYN NFT Pool'),
        href: '/spyn-nft-pools',
      }
    ],
  },
  {
    label: t('REFERRAL'),
    href: '/referral',
    icon: 'Ticket',
    items: [
      {
        label: t('Referral'),
        href: '/referral',
      },
    ],
  },
  {
    label: t('NFTs'),
    href: '/nfts',
    icon: 'Nft',
    items: [
      {
        label: t('NFTs SPYN'),
        href: '/nfts',
      },
      {
        label: t('NFTs Dream'),
        href: '/nfts-dream',
      },
      {
        label: t('NFTs SPY'),
        href: 'https://app.spy-token.io/nfts',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    //   {
    //     label: t('NFT Signatures'),
    //     href: '/signatures',
    //   },
      {
        label: t('Marketplace'),
        href: '/nft-marketplace',
      }
    ]
  },
]

const config = process.env.REACT_APP_HIDE_SPY_POOLS ? configWithoutSpyPools : configWithSpyPools

export default config
