export default {
    lpConverter: {
        97: '0x846f2E13F2f2808a588b84Ab5ad1C84E7274f01B',
        56: '0x63B084F9727239B7442618DeA55157Df08F6F4FB',
    },
  masterChef: {
    97: '0xa3dB9Abb06A0b47d6DE8389dE3ff25bb76c532eE',
    56: '0xf5F8f426ca29535e1147598900E3378F2A9594A9',
  },
  masterChefOld: {
    97: '0xB7CB296869219c984f61575Fc5f5360DAeebc9E1',
    56: '0x5DC034cD31A01108Faaa498Abd2ab63Ff01c3Ac0',
  },
  sousChef: {
    97: '0x7B8e2CE337cfEad3633EeB8dDDC242e76fF1A613',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  signatureFactory: {
    97: '0x96513d2471ebABce7ee107F4dB047E052c347Bc0',
    56: '0x80E3989a551253a9dB4d0720436e17f342F1c512',
  },
  signatureMintProxy: {
    97: '0x1c1b46dC4C99A954358F14940f17c6Fd33534864',
    56: '0x80E3989a551253a9dB4d0720436e17f342F1c512',
  },
  signatureReward: {
    97: '0xC80f739313F7149E75FD15d14BE793867D3ce08b',
    56: '0x80E3989a551253a9dB4d0720436e17f342F1c512',
  },
  signatureInsurance: {
    97: '0x6844c91a589F0Df6E82a9243beb0f3daf46f23F5',
    56: '0x631e57b683537A19B2Bff968D742587672211D51',
  },
  spynNftFactory: {
    97: '0x79D7a4dbE1F745B5Dd87963964DC970e89DD8699',
    56: '0x80E3989a551253a9dB4d0720436e17f342F1c512',
  },
  spynNftMintProxy: {
    97: '0xf2293a1E3c8c874FB45c988FD9Ca30Da1179B85a',
    56: '0x09528A8DC7c7A80db940783bC737C81916AB659e',
  },
  spyNftFactory: {
    97: '0x3E072f99aE6086de6C9d3B7e88C4D86532dFA224',
    56: '0x80E3989a551253a9dB4d0720436e17f342F1c512',
  },
  spynNFTReward: {
    97: '0xEb254910FAC8B198fA492d7B3B01BB194101f9e4',
    56: '0x631e57b683537A19B2Bff968D742587672211D51',
  },
  spyNFTReward: {
    97: '0xA569D7B045223412114dd6643A88901e5F2a4Adf',
    56: '0x631e57b683537A19B2Bff968D742587672211D51',
  },
  nftMarketplace: {
    97: '0x89D34D09665075fD0877a60cC758f587b439e41A',
    56: '0xdA05472a209Efdc1494387e405E230f364da61a3',
  },
  spynNft2Factory: {
    97: '0xE7Cc3c6F3A78CCC5913393D1F790056d9681c7F0',
    56: '0x80E3989a551253a9dB4d0720436e17f342F1c512',
  },
  spynNft2MintProxy: {
    97: '0x62d231D8215c06cA1C74c1010Fa73F8e6555742C',
    56: '0x09528A8DC7c7A80db940783bC737C81916AB659e',
  },
  spynNFT2Reward: {
    97: '0x9dE84D9fee035aBD68266fB10a34c5D011C3fA0B',
    56: '0x631e57b683537A19B2Bff968D742587672211D51',
  },
  governance: {
    97: '0x2059430cC96c59A2c7118B83E4bd47824a9EF2c7',
    56: '0x5c8d87435bcd8582dc01c8103737791f798a1520',
  },
  admin: {
    97: '0xCefAf83154B87AA8c0AbC1070134155056B05cE0',
    56: '0xC0e82D18B54038c100269C913A63FD973ea18404',
  },
  adminOld: {
    97: '0xC43CEcB2140F3149f51fe04a9BE9853410AcDdad',
    56: '0xC0e82D18B54038c100269C913A63FD973ea18404',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    // 97: '0xfb3ad4656eE1Dd1687c0F2007335a0eC9a02E851',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  spynVault: {
    56: '0x6373b4B78b545E76436ED3869ac7B43eeFB39EAc',
    97: '0xa0A58f9CfD720ab0D4ba10Ed2715cF949e673B47',
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialSpynVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  referral: {
    56: '0x0C4EbFbD7d8F881242cd7d32B3e3101a6986636B',
    97: '0xb1EF16B32fb07BE8fE3739f56B6fA6b353C6610d',
  },
}