import { farmsSlice } from 'state/farms'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 3) should always be at the top of the file.
   */
   
  {
    pid: 3,
    lpSymbol: 'BUSD-BNB',
    lpAddresses: {
      97: '0xfe5E0e5c744f4805a538357FD622AC07b551C7a4',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  {
   pid: 1,
   lpSymbol: 'SPYN-BUSD',
   lpAddresses: {
     97: '0xF9addC84D1D61c4261F797089e55c1E0920874d3',
     56: '0x0e587eaeFC234965ef5b2219983E7Df06b42dAE3',
   },
   token: serializedTokens.spyn,
   quoteToken: serializedTokens.busd,
   stakingToken: serializedTokens.busd,
 },
 {
   pid: 2,
   lpSymbol: 'SPYN-BNB',
   lpAddresses: {
     97: '0x2a4a6f0163900d30469e780cb8739fc4df8cbecf',
     56: '0xfcB07994C68d986D4d534709314A021e56bBBFf0',
   },
   token: serializedTokens.spyn,
   quoteToken: serializedTokens.wbnb,
   stakingToken: serializedTokens.wbnb,
 },
 {
   pid: 4,
   lpSymbol: 'SPYN-USDC',
   lpAddresses: {
     97: '0x296ae37b2366e76A228f439826e5B413465162b4',
     56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
   },
   token: serializedTokens.spyn,
   quoteToken: serializedTokens.usdc,
   stakingToken: serializedTokens.usdc,
 },
 {
   pid: null,
   lpSymbol: 'USDC-BNB',
   lpAddresses: {
     97: '0x6255381c390d833114ba5121df4D8Ac1Ce8B868A',
     56: '0xd99c7F6C65857AC913a8f880A4cb84032AB2FC5b',
   },
   token: serializedTokens.usdc,
   quoteToken: serializedTokens.wbnb,
 },
 {
  pid: 5,
  lpSymbol: 'SPYN-SPY',
  lpAddresses: {
    97: '0xf63939219f12D687cb8BC7019D3C2831d1C3dA68',
    56: '0x0e587eaeFC234965ef5b2219983E7Df06b42dAE3',
  },
  token: serializedTokens.spy,
  quoteToken: serializedTokens.spyn,
  stakingToken: serializedTokens.spy,
  }

]

if (!process.env.REACT_APP_HIDE_SPY_POOLS) {
  farms.push({
    pid: 6,
    lpSymbol: 'SPY-BUSD',
    lpAddresses: {
      97: '0xF78f6F88875143b439e5061e78F5088c7b92ceeB',
      56: '0x0e587eaeFC234965ef5b2219983E7Df06b42dAE3',
    },
    token: serializedTokens.spy,
    quoteToken: serializedTokens.busd,
  })
  farms.push(
  {
    pid: 7,
    lpSymbol: 'SPY-BNB LP',
    lpAddresses: {
      97: '0xeEd4b4B832bb2A1276f8287f46025c7593B14888',
      56: '0xfcB07994C68d986D4d534709314A021e56bBBFf0',
    },
    token: serializedTokens.spy,
    quoteToken: serializedTokens.wbnb,
  })
  farms.push(
  {
    pid: 8,
    lpSymbol: 'SPY-USDC LP',
    lpAddresses: {
      97: '0x8b3B214a0159cbB4600B13321F33e92F3B4Aa4A9',
      56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
    },
    token: serializedTokens.spy,
    quoteToken: serializedTokens.usdc,
  })
}

export default farms