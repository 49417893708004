import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.spyn,
    earningToken: serializedTokens.spyn,
    contractAddress: {
      97: '0xD0D0460b3584CBcB137Bf751D10491631bE365Da',
      56: '0x5DC034cD31A01108Faaa498Abd2ab63Ff01c3Ac0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    isFinished: false,
  }
]

export default pools