import { serializeTokens } from './tokens'
import { SerializedVaultConfig } from './types'

const serializedTokens = serializeTokens()


export const oldVaults: SerializedVaultConfig[] = [
    {
        pid: 1,
        lpSymbol: 'SPYN-BUSD',
        symbol: 'BUSD',
        lpAddresses: {
            97: '0xF9addC84D1D61c4261F797089e55c1E0920874d3',
            56: '0x0e587eaeFC234965ef5b2219983E7Df06b42dAE3',
        },
        contractAddresses: {
            97: '0xd027e323F24ffdA23538947adc693B969642fAA4',
            56: '0x30754cF864903232bf5eBC12af929b168Ea1E6DC',
        },
        isETH: false,
        token: serializedTokens.busd,
    },
    {
        pid: 2,
        symbol: 'BNB',
        lpSymbol: 'SPYN-BNB',
        lpAddresses: {
            97: '0x2a4a6f0163900d30469e780cb8739fc4df8cbecf',
            56: '0xfcB07994C68d986D4d534709314A021e56bBBFf0',
        },
        contractAddresses: {
            97: '0xb97E941abf6cdBa748e6bAd82c44ED430C574b28',
            56: '0x42F8479fCe1053cefe8d95441F61DD5C2942F1E4',
        },
        isETH: true,
        token: serializedTokens.wbnb,
    },
    {
        pid: 4,
        symbol: 'USDC',
        lpSymbol: 'SPYN-USDC',
        lpAddresses: {
            97: '0x296ae37b2366e76A228f439826e5B413465162b4',
            56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
        },
        contractAddresses: {
            97: '0xAB5a1d1e40eBAF11a447CAF09E1F1bE926096bf1',
            56: '0x4E24bE6d7234d2e82F7C0F623AC69B434190006D',
        },
        isETH: false,
        token: serializedTokens.usdc,
    },
    {
        pid: 5,
        symbol: 'SPY',
        lpSymbol: 'SPYN-SPY',
        lpAddresses: {
            97: '0xf63939219f12D687cb8BC7019D3C2831d1C3dA68',
            56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
        },
        contractAddresses: {
            97: '0xf17293132b9fFb529D5b3dCb615aF94F10efaD81',
            56: '0x4E24bE6d7234d2e82F7C0F623AC69B434190006D',
        },
        isETH: false,
        token: serializedTokens.spy,
    }
]


const vaults: SerializedVaultConfig[] = [
    {
        pid: 1,
        lpSymbol: 'SPYN-BUSD',
        symbol: 'BUSD',
        lpAddresses: {
            97: '0xF9addC84D1D61c4261F797089e55c1E0920874d3',
            56: '0x0e587eaeFC234965ef5b2219983E7Df06b42dAE3',
        },
        contractAddresses: {
            97: '0x73061Ddca125F8BD1C7bCE7DAa680dC53eAA4236',
            56: '0x30754cF864903232bf5eBC12af929b168Ea1E6DC',
        },
        isETH: false,
        token: serializedTokens.busd,
    },
    {
        pid: 2,
        symbol: 'BNB',
        lpSymbol: 'SPYN-BNB',
        lpAddresses: {
            97: '0x2a4a6f0163900d30469e780cb8739fc4df8cbecf',
            56: '0xfcB07994C68d986D4d534709314A021e56bBBFf0',
        },
        contractAddresses: {
            97: '0x83e3Ef5453751C9F80c5685F1481D4be5E2857f3',
            56: '0x42F8479fCe1053cefe8d95441F61DD5C2942F1E4',
        },
        isETH: true,
        token: serializedTokens.wbnb,
    },
    {
        pid: 4,
        symbol: 'USDC',
        lpSymbol: 'SPYN-USDC',
        lpAddresses: {
            97: '0x296ae37b2366e76A228f439826e5B413465162b4',
            56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
        },
        contractAddresses: {
            97: '0x8652f99D88F64Bb61c0Bb0cDd8F70c2c9b3Aea3f',
            56: '0x4E24bE6d7234d2e82F7C0F623AC69B434190006D',
        },
        isETH: false,
        token: serializedTokens.usdc,
    },
    {
        pid: 5,
        symbol: 'SPY',
        lpSymbol: 'SPYN-SPY',
        lpAddresses: {
            97: '0xf63939219f12D687cb8BC7019D3C2831d1C3dA68',
            56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
        },
        contractAddresses: {
            97: '0xAD71056d403EF3ff834Eb0FaC521a5B9Eb0631c4',
            56: '0x4E24bE6d7234d2e82F7C0F623AC69B434190006D',
        },
        isETH: false,
        token: serializedTokens.spy,
    },
]

export default vaults