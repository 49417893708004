import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'

import BigNumber from 'bignumber.js'
import tokens from 'config/constants/tokens'
import { BIG_ZERO } from 'utils/bigNumber'
import { isAddressSpynNft2, isAddressSpynNft } from 'utils/calls'
import useRefresh from 'hooks/useRefresh'
import { fetchNFTAllowancesAsync, fetchNFTPoolPublicDataAsync, fetchNFTPoolUserDataAsync, fetchNFTSignatureFactoryPublicDataAsync, fetchNFTSignaturePoolPublicDataAsync, fetchNFTSignaturePoolUserDataAsync, fetchNFTSignatureUserBalanceDataAsync, fetchNFTUserBalanceDataAsync, fetchNFTUserDataAsync } from '.'
import { DeserialzedNFTPoolUserData, DeserialzedNFTPoolPublicData, State, DeserializedNFTGego } from '../types'

export const usePollNFTSignaturePublicData = () => {
    const dispatch = useAppDispatch()
    const { slowRefresh } = useRefresh()
    const { account } = useWeb3React()
    useEffect(() => {
      dispatch(fetchNFTSignaturePoolPublicDataAsync())
      dispatch(fetchNFTSignatureFactoryPublicDataAsync())
      if (account) {
        dispatch(fetchNFTUserDataAsync({account}))
        dispatch(fetchNFTSignaturePoolUserDataAsync({account}))
        dispatch(fetchNFTSignatureUserBalanceDataAsync({account}))
      }
      
    }, [dispatch, slowRefresh, account])
  }
  

export const usePollNFTPublicData = () => {
    const dispatch = useAppDispatch()
    const { slowRefresh } = useRefresh()
    const { account } = useWeb3React()
    useEffect(() => {
      dispatch(fetchNFTPoolPublicDataAsync())
      if (account) {
        dispatch(fetchNFTUserDataAsync({account}))
        dispatch(fetchNFTPoolUserDataAsync({account}))
        dispatch(fetchNFTUserBalanceDataAsync({account}))
      }
      
    }, [dispatch, slowRefresh, account])
}

export const usePollNFTAllowanceData = () => {
    const dispatch = useAppDispatch()
    const { account } = useWeb3React()
    useEffect(() => {
      if (account) {
        dispatch(fetchNFTAllowancesAsync({account}))
      }
      
    }, [dispatch, account])
}

export const useSignatureBalances = () : DeserializedNFTGego[] => {
  const nftBalance = useSelector((state: State) => state.nft.signatureBalance)

  const deserlizedNFTBalance = nftBalance.map((gego) => {
    return {
      address: gego.address,
      token: tokens.spyn,
      staked: gego.staked,
      id: gego.id,
      grade: gego.grade,
      lockedDays: gego.lockedDays,
      blockNum: new BigNumber(gego.blockNum),
      createdTime: gego.createdTime,
      quality: gego.quality,
      amount: new BigNumber(gego.amount),
      realAmount: gego.realAmount ? new BigNumber(gego.realAmount) : undefined,
      efficiency: gego.efficiency ? new BigNumber(gego.efficiency) : BIG_ZERO,
      expiringTime: gego.expiringTime ? new BigNumber(gego.expiringTime) : undefined,
      resBaseId: new BigNumber(gego.resBaseId),
    }
  })

  return deserlizedNFTBalance
}


export const useNFTSignatureFactoryData = () => {
    const publicData = useSelector((state: State) => state.nft.signatureFactoryData)
    return {
        activeRuleId: publicData ? new BigNumber(publicData.activeRuleId) : BIG_ZERO,
        maxMintAmount: publicData ? new BigNumber(publicData.maxMintAmount) : BIG_ZERO,
        maxMintQuantity: publicData ? new BigNumber(publicData.maxMintQuantity) : BIG_ZERO,
        maxMintQuantityPerClick: publicData ? new BigNumber(publicData.maxMintQuantityPerClick) : BIG_ZERO,
        mintCost: publicData ? new BigNumber(publicData.mintCost) : BIG_ZERO,
        mintCostDiscount: publicData ? new BigNumber(publicData.mintCostDiscount) : BIG_ZERO,
        mintCostDiscountQuantity: publicData ? new BigNumber(publicData.mintCostDiscountQuantity) : BIG_ZERO,
        mintedQuantity: publicData ? new BigNumber(publicData.mintedQuantity) : BIG_ZERO,
        costToken: publicData ? publicData.costToken : undefined,
    }
}

export const useNFTBalances = (nftAddress?: string) : DeserializedNFTGego[] => {
  const nftBalance = useSelector((state: State) => state.nft.spynNftBalance)
  const nft2Balance = useSelector((state: State) => state.nft.spynNft2Balance)
  const spyBalance = useSelector((state: State) => state.nft.spyNftBalance)
  let balance = nftBalance
  if (isAddressSpynNft2(nftAddress)) {
    balance = nft2Balance
  } else if (!isAddressSpynNft(nftAddress)) {
    balance = spyBalance
  }
  const deserlizedNFTBalance = balance.map((gego) => {
    return {
      address: gego.address,
      token: tokens.spyn,
      staked: gego.staked,
      id: gego.id,
      grade: gego.grade,
      lockedDays: gego.lockedDays,
      blockNum: new BigNumber(gego.blockNum),
      createdTime: gego.createdTime,
      quality: gego.quality,
      amount: new BigNumber(gego.amount),
      realAmount: gego.realAmount ? new BigNumber(gego.realAmount) : undefined,
      efficiency: gego.efficiency ? new BigNumber(gego.efficiency) : BIG_ZERO
    }
  })

  return deserlizedNFTBalance
}

export const useSpyNFTBalances = () : DeserializedNFTGego[] => {
  const nftBalance = useSelector((state: State) => state.nft.spyNftBalance)

  const deserlizedNFTBalance = nftBalance.map((gego) => {
    return {
      address: gego.address,
      token: tokens.spy,
      staked: gego.staked,
      id: gego.id,
      grade: gego.grade,
      lockedDays: gego.lockedDays,
      blockNum: new BigNumber(gego.blockNum),
      createdTime: gego.createdTime,
      quality: gego.quality,
      amount: new BigNumber(gego.amount),
      realAmount: gego.realAmount ? new BigNumber(gego.realAmount) : undefined,
      efficiency: gego.efficiency ? new BigNumber(gego.efficiency) : BIG_ZERO
    }
  })

  return deserlizedNFTBalance
}

export const useNFTCastAllowance = (nftAddress?: string) =>  {
  const castNFTAllowance = useSelector((state: State) => state.nft.castNFTAllowance)
  const castNFT2Allowance = useSelector((state: State) => state.nft.castNFT2Allowance)
  const nftAllowance = castNFTAllowance ? new BigNumber(castNFTAllowance) : BIG_ZERO
  const nft2Allowance = castNFT2Allowance ? new BigNumber(castNFT2Allowance) : BIG_ZERO
  return isAddressSpynNft2(nftAddress) ? nft2Allowance : nftAllowance
}

export const useNFTFactoryAllowance = (nftAddress?: string) => {
  const nftFactoryAllowance = useSelector((state: State) => state.nft.spynFactoryAllowance)
  const nft2FactoryAllowance = useSelector((state: State) => state.nft.spyn2FactoryAllowance)
  return isAddressSpynNft2(nftAddress) ? nft2FactoryAllowance : nftFactoryAllowance
}

export const useNFTRewardAllowance = (nftAddress?: string) : boolean => {
  const spynRewardAllowance =  useSelector((state: State) => state.nft.spynRewardAllowance)
  const spyn2RewardAllowance =  useSelector((state: State) => state.nft.spyn2RewardAllowance)
  const spyRewardAllowance =  useSelector((state: State) => state.nft.spyRewardAllowance)
  const signatureRewardAllowance = useSelector((state: State) => state.nft.signatureRewardAllowance)
  if (isAddressSpynNft2(nftAddress)) {
      return spyn2RewardAllowance
  }
  if (isAddressSpynNft(nftAddress)) {
    return spyRewardAllowance
  }
  if (nftAddress?.toLowerCase() === tokens.signature.address.toLowerCase()) {
    return signatureRewardAllowance
  }
  return spynRewardAllowance
}

export const useNFTMarketplaceAllowance = (nftAddress?: string) => {
const marketplaceAllowance = useSelector((state: State) => state.nft.spynMarketplaceAllowance)
const spyn2MarketplaceAllowance = useSelector((state: State) => state.nft.spyn2MarketplaceAllowance)
  const signatureMarketplaceAllowance = useSelector((state: State) => state.nft.signatureMarketplaceAllowance)

  const allowance = nftAddress?.toLowerCase() === tokens.signature.address.toLowerCase() ? signatureMarketplaceAllowance : nftAddress?.toLowerCase() === tokens.spynnft.address.toLowerCase() ? marketplaceAllowance : spyn2MarketplaceAllowance
  return allowance
}

export const useSpyNFTRewardAllowance = () => {
  return useSelector((state: State) => state.nft.spyRewardAllowance)
}
export const useNFTPoolUserData = (nftAddress?: string) : DeserialzedNFTPoolUserData => {
  const spynUserData = useSelector((state: State) => state.nft.spynPoolUserData)
  const spyn2UserData = useSelector((state: State) => state.nft.spyn2PoolUserData)
  const spyUserData = useSelector((state: State) => state.nft.spyPoolUserData)
  const userData = isAddressSpynNft2(nftAddress) ? spyn2UserData : nftAddress?.toLowerCase () === tokens.spynft.address.toLowerCase() ? spyUserData : spynUserData
  return {
    balance: userData ? new BigNumber(userData.balance) : BIG_ZERO,
    earning: userData ? new BigNumber(userData.earning) : BIG_ZERO,
    nextHarvestUntil: userData ? userData.nextHarvestUntil : 0,
    extraHarvestInterval: userData ? userData.extraHarvestInterval : 0,
    userDataLoaded: userData ? userData.userDataLoaded : false
  }
}
export const useSpyNFTPoolUserData = () : DeserialzedNFTPoolUserData => {
  const userData = useSelector((state: State) => state.nft.spyPoolUserData)
  return {
    balance: userData ? new BigNumber(userData.balance) : BIG_ZERO,
    earning: userData ? new BigNumber(userData.earning) : BIG_ZERO,
    nextHarvestUntil: userData ? userData.nextHarvestUntil : 0,
    extraHarvestInterval: userData ? userData.extraHarvestInterval : 0,
    userDataLoaded: userData ? userData.userDataLoaded : false
  }
}

export const useNFTPoolPublicData = (nftAddress?: string) : DeserialzedNFTPoolPublicData => {
  const spynPublidData = useSelector((state: State) => state.nft.spynPoolPublicData)
  const spyn2PublidData = useSelector((state: State) => state.nft.spyn2PoolPublicData)
  const spyPublidData = useSelector((state: State) => state.nft.spyPoolPublicData)
  const publidData = isAddressSpynNft2(nftAddress) ? spyn2PublidData : nftAddress?.toLowerCase () === tokens.spynft.address.toLowerCase() ? spyPublidData : spynPublidData
  return {
    harvestInterval: publidData ? publidData.harvestInterval : 0,
    periodFinish: publidData ? publidData.periodFinish : 0,
    rewardPerTokenStored: publidData ? new BigNumber(publidData.rewardPerTokenStored) : BIG_ZERO,
    rewardRate: publidData ? new BigNumber(publidData.rewardRate) : BIG_ZERO,
    rewardPrecisionFactor: publidData ? new BigNumber(publidData.rewardPrecisionFactor) : BIG_ZERO,
    totalSupply: publidData ? new BigNumber(publidData.totalSupply) : BIG_ZERO,
    totalBalance: publidData ? new BigNumber(publidData.totalBalance) : BIG_ZERO,
    harvestFee: publidData ? new BigNumber(publidData.harvestFee) : BIG_ZERO
  }
}

export const useSpyNFTPoolPublicData = () : DeserialzedNFTPoolPublicData => {
  const publidData = useSelector((state: State) => state.nft.spyPoolPublicData)
  return {
    harvestInterval: publidData ? publidData.harvestInterval : 0,
    periodFinish: publidData ? publidData.periodFinish : 0,
    rewardPerTokenStored: publidData ? new BigNumber(publidData.rewardPerTokenStored) : BIG_ZERO,
    rewardRate: publidData ? new BigNumber(publidData.rewardRate) : BIG_ZERO,
    rewardPrecisionFactor: publidData ? new BigNumber(publidData.rewardPrecisionFactor) : BIG_ZERO,
    totalSupply: publidData ? new BigNumber(publidData.totalSupply) : BIG_ZERO,
    totalBalance: publidData ? new BigNumber(publidData.totalBalance) : BIG_ZERO,
    harvestFee: publidData ? new BigNumber(publidData.harvestFee) : BIG_ZERO
  }
}

export const useNFTSignatureRewardAllowance = () => {
    return useSelector((state: State) => state.nft.signatureRewardAllowance)
}

export const useNFTSignaturePoolPublicData = () : DeserialzedNFTPoolPublicData => {
    const publidData = useSelector((state: State) => state.nft.signaturePoolPublicData)
    return {
        harvestInterval: publidData ? publidData.harvestInterval : 0,
        periodFinish: publidData ? publidData.periodFinish : 0,
        rewardPerTokenStored: publidData ? new BigNumber(publidData.rewardPerTokenStored) : BIG_ZERO,
        rewardRate: publidData ? new BigNumber(publidData.rewardRate) : BIG_ZERO,
        rewardPrecisionFactor: publidData ? new BigNumber(publidData.rewardPrecisionFactor) : BIG_ZERO,
        totalSupply: publidData ? new BigNumber(publidData.totalSupply) : BIG_ZERO,
        totalBalance: publidData ? new BigNumber(publidData.totalBalance) : BIG_ZERO,
        harvestFee: publidData ? new BigNumber(publidData.harvestFee) : BIG_ZERO
    }
}


export const useNFTSignaturePoolUserData = () : DeserialzedNFTPoolUserData => {
    const userData = useSelector((state: State) => state.nft.signaturePoolUserData)
    return {
        balance: userData ? new BigNumber(userData.balance) : BIG_ZERO,
        earning: userData ? new BigNumber(userData.earning) : BIG_ZERO,
        nextHarvestUntil: userData ? userData.nextHarvestUntil : 0,
        userDataLoaded: userData ? userData.userDataLoaded : false,
        extraHarvestInterval: 0
    }
}